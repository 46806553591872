import React, { useEffect, useState } from "react";
import home_img from "../assets/home_img-2.jpg";
import Home_img_1 from "../assets/Home_img1.jpg";
import Home_img_2 from "../assets/home_img-2.jpg";
import Home_img3 from "../assets/Home_img3.jpeg";
import route_1 from "../assets/route-1.png";
import route_2 from "../assets/route-2.png";
import Main_video from "../assets/main_video.mp4";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Section } from "../components";

const HomeScreen = () => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 3000);
  }, []);

  return (
    <div className="max-w-[1600px] mx-auto">
      <Helmet>
        <title>
          Best Hotel, Resort , Cafe and Restaurant in Chopta - The Bunker House
        </title>
        <meta
          name="description"
          content="The Bunker House is the best resort and Hotel in Chopta. Enjoy top-notch accommodations, cafe, and restaurant in the best hotel and resort in Chopta."
        />
      </Helmet>

      <div className="relative">
        {/* <img
          src={home_img}
          alt=""
          className="w-full h-[90vh] object-cover object-center"
        /> */}
        <video loop autoPlay muted>
          <source src={"/TBH-Teaser.mp4"} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {showButton && (
          <div className="mx-auto absolute z-20 top-1/2 text-center align-middle w-full">
            <button
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=jO630d4A4J4",
                  "_blank"
                )
              }
              className="border-white border-2 hover:bg-app-primary hover:border-app-primary mx-auto bg-transparent text-white px-3 py-2 "
            >
              WATCH ON YOUTUBE
            </button>
          </div>
        )}
      </div>

      <div className="px-5">
        <Section>
          <Container>
            <h1 className="text-4xl mt-5 font-bold text-center text-[#6dc1b2]">
              Introduction
            </h1>
            <div className="pt-10 pb-4">
              <div className="flex items-center flex-col lg:flex-row xl:flex-row">
                <img
                  src={Home_img_1}
                  alt=""
                  className="rounded-md w-screen lg:w-[50%] xl:w-[50%]"
                />
                <div className="text-center xl:text-left lg:text-left p-3 pb-5 xl:px-20 xl:py-10 h-[100%] w-screen lg:w-[50%] xl:w-[50%]">
                  <p className="leading-7 mb-3">
                    Amidst the endearing meadows and playful streams, surrounded
                    by the majestic peaks of Chandrashila, Nanda Devi and
                    Chowkhamba, and blessed by the world's highest Shiva temple
                    - The Shrine of Tungnath, lies the quaint valley of Chopta.
                    The valley is crowded not by people, but myriad natural
                    wonders like waterfalls, evergreen forests and innumerable
                    species of birds and animals.
                  </p>
                </div>
              </div>
            </div>
          </Container>

          <Container>
            <div className="flex items-center flex-col lg:flex-row xl:flex-row">
              <div className="text-center xl:text-left lg:text-left p-3 pb-5 xl:px-20 xl:py-10 h-[100%] w-screen lg:w-[50%] xl:w-[50%]">
                <p className="leading-7 mb-3">
                  The Bunker House, an unparalleled Cafe and Stay property
                  located in this very ‘Mini Switzerland of Uttarakhand’,
                  empowers its visitors to experience the spectacular beauty of
                  Chopta without compromising on the comfort.
                </p>
                <p className="leading-7 mb-3">
                  {" "}
                  The Multi - Cuisine Cafe is built on the concept of shared
                  experiences, with a common area complete with fun activities
                  and a distinguished view, fit for both indoor and outdoor fun.
                  The accommodation is focused on ensuring privacy while also
                  giving an option to bunk together. The all over decor blends
                  with the earthy tones of the surroundings and radiates a
                  homely vibe.
                </p>
              </div>
              <img
                src={Home_img_2}
                alt=""
                className="rounded-md w-screen lg:w-[50%] xl:w-[50%]"
              />
            </div>
          </Container>

          <Container>
            <div className="flex items-center flex-col lg:flex-row xl:flex-row mt-4">
              <img
                src={Home_img3}
                alt=""
                className="rounded-md  w-screen lg:w-[50%] xl:w-[50%]"
              />
              <div className="text-center xl:text-left lg:text-left p-3 pb-5 xl:px-20 xl:py-10 h-[100%] w-screen lg:w-[50%] xl:w-[50%]">
                <p className="leading-7 mb-3">
                  With an unrelenting commitment to the environment, we use
                  solar power and organise adventurous yet environment friendly
                  activities that take your exploration of the beauty of Chopta
                  up a notch. A humble and contemporary start up, we stand for
                  quality and integrity in all that we do, and strive to make
                  The Bunker House your <b>home, away from home.</b>
                </p>
              </div>
            </div>
          </Container>
        </Section>
      </div>

      <Section>
        <Container>
          <div className="text-center">
            <h1 className="text-4xl font-bold  text-[#6dc1b2]">Routes</h1>

            <div className="flex flex-col items-center justify-start">
              <h3 className="text-2xl font-semibold mt-5 text-center text-[#6dc1b2]">
                Popular Routes To Reach The Bunker House
              </h3>
              <button
                onClick={() => {
                  window.open(
                    "https://www.google.com/maps/dir//the+bunker+house+chopta",
                    "_blank"
                  );
                }}
                className="py-1 px-6 font-medium text-sm w-fit text-black bg-teal-100 rounded-md hover:text-app-secondary transition duration-300 my-3"
              >
                View on Google Maps
              </button>
            </div>
            <div className="flex items-center flex-col lg:flex-row gap-10 mt-5">
              <div className="flex flex-col  items-center justify-center">
                <img src={route_1} alt="" className="rounded-md w-full" />
              </div>

              <div className="flex flex-col  items-center justify-center">
                <img src={route_2} alt="" className="rounded-md w-full" />
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </div>
  );
};

export default HomeScreen;
